<template>
    <div class="main-box">
        <div class="pd-box">
            <div style="text-align: center;font-size: 26px;font-weight: bold;">{{detail.MESSAGE}}</div>
            <div style="display: flex;margin: 15px 0 ;">
                <div style="width: 50%;text-align: right;">发布时间：{{detail.CTIME}}</div>
                <div style="width: 50%;margin-left: 20px;">发布人：{{detail.SEND_AUTHOR}}</div>
            </div>
            <div style="text-indent: 20px;">{{detail.INFO_DETAIL}}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    computed: {
        ...mapGetters(['userId', 'userName'])
    },
    data () {
        return {
            id: '',
            detail: ''
        }
    },
    methods: {
        getSysMessageDetail () {
            this.$store.dispatch('getSysMessageDetail', { SYSMESSAGE_ID: this.id, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    if (res.pd !== '' && res.pd !== null) {
                        res.pd.CTIME = res.pd.CTIME.split('T')[0]
                        this.detail = res.pd
                    } else {
                        this.detail = ''
                    }
                } else {
                    this.detail = ''
                    this.$message.error('系统异常，获取消息详情失败，请稍后重试！')
                }
            })
        }
    },
    created () {
        this.id = this.$route.query.id
        this.getSysMessageDetail()
    }
}
</script>
<style lang="less" scoped>
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .pd-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
    }

    .pd-box::before{
        content: '';
        position: absolute;
        left: -3px;
        top: 10px;
        width: 6px;
        height: 40px;
        background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
        background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */ 
        background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
        background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
    }
}
</style>